import React from "react";

import { Main } from "~/layouts";
import { Seo } from "~/components/seo";
import { HeroTitle } from "~/components/hero";
import { MarketingBlock, MarketingCopy } from "~/components/marketing";
import { Footer } from "~/components/footer";

const BecomeASellerPage = () => (
  <Main fullView>
    <Seo title="Become a Seller" />
    <HeroTitle
      title="Become a Seller"
      align="center"
    />

    <MarketingBlock>
      <MarketingCopy as="div">
        <p>Here at The Good Snail, we love greeting card designers just as much as the cards themselves. We value designer's art and that is why we promote their label on the back of each card.</p>
        <p>If you are interested in licensing your work, please reach out to <a href="mailto:hello@thegoodsnail.com?subject=Seller Inquiry">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>
    <Footer />
  </Main>
);

export default BecomeASellerPage;
